<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="centers"
      :page.sync="page"
      :items-per-page="centersPerPage"
      :server-items-length="totalCenters"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-tabs height="40" class="mt-4">
            <v-tab class="btn" @click="loadCenters">
              All
            </v-tab>
            <v-tab class="btn" @click="loadTransitCenters">
              Transit
            </v-tab>
            <v-tab class="btn" @click="loadRehabCenters">
              Rehab
            </v-tab>
          </v-tabs>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate"
                >New Center</v-btn
              >
            </template>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Center Name"
                          >
                            <v-text-field
                              dense
                              label="Center Name"
                              type="text"
                              outlined
                              v-model="editedCenter.name"
                              name="Center Name"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Center Type"
                          >
                            <v-select
                              dense
                              :items="centerTypes"
                              label="Type"
                              outlined
                              item-text="type"
                              item-value="type"
                              offset-y
                              v-model="editedCenter.type"
                              name="Center Type"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Center Category"
                          >
                            <v-select
                              dense
                              :items="categories"
                              label="Category"
                              item-text="name"
                              item-value="value"
                              outlined
                              v-model="editedCenter.public"
                              class="mt-n6"
                              name="Center Category"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="numeric"
                            name="Stay Time"
                          >
                            <v-text-field
                              dense
                              label="Stay Time"
                              type="staytime"
                              outlined
                              v-model="editedCenter.stayTime"
                              class="mt-n6"
                              name="Stay Time"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-select
                            dense
                            :items="timeUnits"
                            label="Time Unit"
                            item-text="name"
                            outlined
                            v-model="editedCenter.stayTimeMeasure"
                            class="mt-n6"
                            color="grey"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="numeric"
                            name="Capacity"
                          >
                            <v-text-field
                              dense
                              label="Capacity"
                              type="capacity"
                              outlined
                              v-model="editedCenter.capacity"
                              class="mt-n6"
                              name="Capacity"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="numeric"
                            name="Telephone"
                          >
                            <v-text-field
                              dense
                              label="Telephone"
                              type="telephone"
                              outlined
                              v-model="editedCenter.phone"
                              class="mt-n6"
                              name="Telephone"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="email"
                            name="Center Email"
                          >
                            <v-text-field
                              dense
                              label="Email"
                              type="email"
                              outlined
                              v-model="editedCenter.email"
                              class="mt-n6"
                              name="Center Email"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="provinces"
                            item-text="name"
                            item-value="id"
                            dense
                            v-model="editedCenter.address.province.id"
                            label="Province"
                            @change="loadDistricts"
                            outlined
                            class="mt-n6"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="districts"
                            item-text="name"
                            item-value="id"
                            dense
                            v-model="editedCenter.address.district.id"
                            label="District"
                            @change="loadSectors"
                            outlined
                            class="mt-n6"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="sectors"
                            item-text="name"
                            item-value="id"
                            dense
                            v-model="editedCenter.address.sector.id"
                            label="Sector"
                            @change="loadCells"
                            outlined
                            class="mt-n6"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="cells"
                            item-text="name"
                            item-value="id"
                            dense
                            v-model="editedCenter.address.cell.id"
                            label="Cell"
                            @change="loadVillages"
                            outlined
                            class="mt-n6"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                            :items="villages"
                            item-text="name"
                            item-value="id"
                            dense
                            v-model="editedCenter.address.village.id"
                            label="Village"
                            outlined
                            class="mt-n6"
                            filled
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="success ml-4 mb-4" text type="submit"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editCenter(item)" v-if="showEdit">
          mdi-pencil
        </v-icon>
        <v-icon small @click.stop="centerDialog(item)" v-if="showDelete">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this center ?</p>
          <v-btn link small color="blue" @click="deleteCenter()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectCentersPerPage"
            v-model="centersPerPage"
            label="Centers per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="2"> {{ totalCenters }} Records </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Center from '@/models/Center'
import Location from '@/models/Location'

export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    centerIndex: null,
    centerItem: null,
    loadCentersFlag: 'loadAllCenters',
    transitId: null,
    rehabId: null,
    policeId: null,
    headQuarterId: null,
    postRehabId: null,
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Phone', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Stay Time', value: 'stay' },
      { text: 'Capacity', value: 'capacity' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    centers: [],
    centerTypes: [],
    categories: [],
    timeUnits: [],
    editedIndex: -1,
    editedCenter: new Center(),
    selectCentersPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    centersPerPage: 20,
    totalCenters: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Center' : 'Edit Center'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser'
    ])
  },
  mounted () {
    this.loadCenters()
    if (this.showCreate || this.showEdit) {
      this.loadDependencies()
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        if (this.loadCentersFlag === 'loadAllCenters') {
          this.loadCenters()
        } else if (this.loadCentersFlag === 'loadTransitCenters') {
          this.loadTransitCenters()
        } else if (this.loadCentersFlag === 'loadRehabCenters') {
          this.loadRehabCenters()
        }
      }
    },
    centersPerPage: {
      handler () {
        this.page = 1
        if (this.loadCentersFlag === 'loadAllCenters') {
          this.loadCenters()
        } else if (this.loadCentersFlag === 'loadTransitCenters') {
          this.loadTransitCenters()
        } else if (this.loadCentersFlag === 'loadRehabCenters') {
          this.loadRehabCenters()
        }
      }
    }
  },

  created () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
    this.loadCenterType()
    this.categories = [
      {
        name: 'Public',
        value: true
      },
      {
        name: 'Private',
        value: false
      }
    ]
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadProvinces () {
      this.districts = []
      this.sectors = []
      this.cells = []
      this.villages = []
      this.$locationService.fetchChildren(0).then(response => {
        if (!response.status) {
          this.provinces = response
          this.districts = []
          this.sectors = []
          this.cells = []
          this.villages = []
          this.editedCenter.address.province = new Location()
          this.editedCenter.address.district = new Location()
          this.editedCenter.address.sector = new Location()
          this.editedCenter.address.cell = new Location()
          this.editedCenter.address.village = new Location()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    loadDistricts (parentId) {
      this.sectors = []
      this.cells = []
      this.villages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.districts = response
          this.editedCenter.address.sector = new Location()
          this.editedCenter.address.cell = new Location()
          this.editedCenter.address.village = new Location()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    loadSectors (parentId) {
      this.cells = []
      this.villages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.sectors = response
          this.editedCenter.address.cell = new Location()
          this.editedCenter.address.village = new Location()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    loadCells (parentId) {
      this.villages = []
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.cells = response
          this.editedCenter.address.village = new Location()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    loadVillages (parentId) {
      this.$locationService.fetchChildren(parentId).then(response => {
        if (!response.status) {
          this.villages = response
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    loadCenters () {
      if (this.loadCentersFlag === 'loadAllCenters') {
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.loadCentersFlag = 'loadAllCenters'
        this.page = 1
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    loadTransitCenters () {
      if (this.loadCentersFlag === 'loadTransitCenters') {
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage, this.transitId)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.loadCentersFlag = 'loadTransitCenters'
        this.page = 1
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage, this.transitId)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    loadRehabCenters () {
      if (this.loadCentersFlag === 'loadRehabCenters') {
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage, this.rehabId)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.loadCentersFlag = 'loadRehabCenters'
        this.page = 1
        const pageNumber = this.page - 1
        this.$centerService
          .fetchAll(pageNumber, this.centersPerPage, this.rehabId)
          .then(response => {
            if (!response.status) {
              this.centers = response.content
              this.totalCenters = response.totalElements
              this.centers.forEach(element => {
                if (
                  element.stayTime !== null &&
                  element.stayTimeMeasure !== null
                ) {
                  element.stay =
                    element.stayTime + ' ' + element.stayTimeMeasure
                } else element.stay = ''
              })
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      }
    },
    loadCenterType () {
      this.$centerService.fetchAllCenterType().then(response => {
        if (!response.status) {
          this.centerTypes = response
          this.centerTypes.forEach(element => {
            if (element.type === 'TRANSIT') {
              this.transitId = element.id
            } else if (element.type === 'REHABILITATION') {
              this.rehabId = element.id
            } else if (element.type === 'POLICE_STATION') {
              this.policeId = element.id
            } else if (element.type === 'NRS_HEADQUARTERS') {
              this.headQuarterId = element.id
            } else if (element.type === 'POST_REHABILITATION') {
              this.postRehabId = element.id
            }
          })
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    loadTimeUnits () {
      this.$centerService.fetchAllTimeUnits().then(response => {
        if (!response.status) this.timeUnits = response
        else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    editCenter (item) {
      this.editedIndex = this.centers.indexOf(item)
      this.$locationService.fetchAllSiblings(item.address).then(response => {
        if (response.status) window.alert(response.message)
        else { [this.districts, this.sectors, this.cells, this.villages] = response }
      })
      this.$centerService.fetchById(item.id).then(response => {
        if (response.id) {
          this.initializeAddressIfNull(response.address)
          this.editedCenter = response
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
      this.dialog = true
    },
    centerDialog (item) {
      this.centerIndex = this.centers.indexOf(item)
      this.centerItem = item
      this.deleteDialog = true
    },
    deleteCenter (item) {
      this.deleteDialog = false
      this.$centerService.deleteCenter(this.centerItem.id).then(response => {
        if (response.status === 'success') {
          this.centers.splice(this.centerIndex, 1)
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    initializeAddressIfNull (address) {
      if (address.province == null) address.province = new Location()
      if (address.district == null) address.district = new Location()
      if (address.sector == null) address.sector = new Location()
      if (address.cell == null) address.cell = new Location()
      if (address.village == null) address.village = new Location()
    },

    close () {
      this.dialog = false
      this.editedCenter = new Center()
      this.editedIndex = -1
    },
    updateCenter () {
      const deletedCenterArray = this.centers.splice(this.editedIndex, 1)
      this.$centerService.saveCenter(this.editedCenter).then(response => {
        this.close()
        if (response.id) {
          this.centers.splice(this.editedIndex, 1, response)
          this.close()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.centers.splice(this.editedIndex, 0, deletedCenterArray[0])
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    createCenter () {
      this.$centerService.saveCenter(this.editedCenter).then(response => {
        if (response.id) {
          if (response.stayTime !== null && response.stayTimeMeasure !== null) {
            response.stay = response.stayTime + ' ' + response.stayTimeMeasure
          } else response.stay = ''
          this.centers.unshift(response)
          this.close()
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1) this.updateCenter()
        else this.createCenter()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    checkPrivileges () {
      this.privileges.includes('CREATE_CENTERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.includes('UPDATE_CENTERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.includes('DELETE_CENTERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showDelete = true)
        : (this.showDelete = false)
    },
    loadDependencies () {
      if (
        (this.privileges.includes('READ_LOCATIONS') &&
          this.privileges.includes('READ_CENTERS')) ||
        this.privileges.includes('ALL_AUTHORITIES')
      ) {
        this.loadProvinces()
        this.loadTimeUnits()
      }
    }
  }
}
</script>
